import { createApp } from 'vue';
import App from './App.vue';
// import ECharts from 'vue-echarts';
// import { use } from 'echarts/core';
// import { CanvasRenderer } from 'echarts/renderers';
// import { BarChart } from 'echarts/charts';
// import { GridComponent, TooltipComponent } from 'echarts/components';
// use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);
// app.component('v-chart', ECharts);

const app = createApp(App);

app.config.productionTip = false;
import EmptyLayout from './layouts/EmptyLayout';
app.component('empty-layout', EmptyLayout);
import BasicLayout from './layouts/BasicLayout';
app.component('basic-layout', BasicLayout);
import api from './request'
app.config.globalProperties.$api = api;
import utils from './utils';
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$d = utils.d;
import async from 'async';
app.config.globalProperties.$async = async;
import axios from 'axios'
app.config.globalProperties.$axios = axios;
import constant from './constant';
app.config.globalProperties.$constant = constant;
import { socket } from './socket';
app.config.globalProperties.$socket = socket;

app.config.globalProperties.$filters = {
  dateFormat: utils.dateTime,
  dateTime: utils.dateTime,
  fromNow: utils.fromNow,
  filterOption: utils.filterOption,
  doCopy: utils.doCopy,
  sizeToText: utils.sizeToText,
  image_placeholder: constant.image_placeholder,
}

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
app.use(Antd);
import VueApexCharts from 'vue3-apexcharts';
app.use(VueApexCharts);
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
app.use(VXETable);
import vPreviewImage from 'v-preview-image';
app.use(vPreviewImage);

import './icons'
import SvgIcon from './components/svgicon/Index.vue'
app.component('svg-icon', SvgIcon)

import router from './router';
app.use(router);

import store from './store';
app.use(store);

app.mount('#app');
