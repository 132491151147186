<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="name" placeholder="输入物流商名称查询" enter-button="搜索" @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">刷新数据</a-button>
    <a-button type="primary" @click="requestBind(null)" :loading="binding"> <template #icon>
        <PlusCircleOutlined />
      </template>添加授权
    </a-button>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id" @expand="expand"
    v-model:expandedRowKeys="expandedRowKeys" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'supplier'">
        <div class="d-flex flex-column align-start">
          <span>{{ record.code }}</span>
          <span>{{ record.name }}</span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'authorize_status'">
        <a-tag color="#87d068">已授权</a-tag>
        <span>物流账号：{{ record.appid }}</span>
      </template>
      <template v-if="column.dataIndex === 'bsellers'">
        <p v-for="seller in record.bsellers" :key="seller._id"> {{ seller.name || seller.username }} </p>
        <a v-if="level === 'master'" href="javascript:;" @click="showAuthSellers(record)">
          授权
        </a>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space v-if="level === 'master' || record.sellerid === userid">
          <a-button type="link" class="text-success" @click="requestBind(record)">修改授权</a-button>
          <a-popconfirm title="删除物流账号?" @confirm="removeBind(record._id)">
            <a-button type="link" class="text-error">删除授权</a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="pcolumns" bordered :data-source="channels[record._id]" :scroll="{ x: pwidth }"
        :loading="channelLoading" :pagination="false" size="small">
        <template #bodyCell="{ column: pcolumn, record: precord, index: pindex }">
          <template v-if="pcolumn.dataIndex === 'index'">
            {{ pindex + 1 }}
          </template>
          <template v-if="pcolumn.dataIndex === 'name'">
            <p>{{ precord.code }}</p>
            <p>{{ precord.name }}</p>
          </template>
          <template v-if="pcolumn.dataIndex === 'carrier'">
            <template v-if="precord.channelbind">
              <span v-if="precord.channelbind.amz_carrier_code">
                亚马逊：<a href="javascript:;" class="text-info underline" @click="showCarrier(precord, record)">
                  {{ precord.channelbind.amz_carrier_code }}
                </a>
              </span>
              <a href="javascript:;" v-else class="text-error underline" @click="showCarrier(precord, record)">
                未设置
              </a>
            </template>
          </template>
          <template v-if="pcolumn.dataIndex === 'state'">
            <a-tag v-if="precord.channelbind?.enabled" color="#87d068">已启用</a-tag>
            <a-tag v-else color="red">未启用</a-tag>
          </template>
          <template v-if="pcolumn.dataIndex === 'remark'">
            <span v-if="precord.channelbind?.remark">
              {{ precord.channelbind.remark }}
              <a href="javascript:;" @click="showRemark(precord, record)">
                <EditOutlined />
              </a>
            </span>
            <a href="javascript:;" v-else-if="precord.channelbind" class="text-error underline"
              @click="showRemark(precord, record)">
              未设置
            </a>
          </template>
          <template v-if="pcolumn.dataIndex === 'operation'">
            <a-space>
              <a-popconfirm v-if="precord.channelbind?.enabled" title="确定要停用?" @confirm="closeChannel(precord, record)">
                <a href="javascript:;" class="text-error">停用</a>
              </a-popconfirm>
              <a v-else href="javascript:;" @click="showCarrier(precord, record)">启用</a>
            </a-space>
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal v-model:visible="bindModal" centered title="授权物流商" @ok="confirmBind" ok-text="确认" cancel-text="取消"
    :confirm-loading="binding" :cancel-button-props="{ disabled: binding }" :maskClosable="false" :closable="!binding">
    <a-form ref="bindRef" name="bindForm" :model="bindForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="物流商" name="logistics_supplier_id" :rules="[{ required: true, message: '请选择物流商' }]">
        <a-select v-model:value="bindForm.logistics_supplier_id" :options="suppliers"
          :disabled="bindForm._id !== '' || binding" @change="changeSupplier" show-search
          :filter-option="$filters.filterOption" />
      </a-form-item>
      <a-form-item label="别名" name="name">
        <a-input v-model:value="bindForm.name" placeholder="请输入物流别名" :disabled="binding">
        </a-input>
      </a-form-item>
      <a-form-item label="物流账号" name="appid" :rules="[{ required: true, message: '请填写物流账号' }]">
        <a-input v-model:value="bindForm.appid" placeholder="请输入物流账号" :disabled="binding">
        </a-input>
      </a-form-item>
      <a-form-item label="密钥" name="appsecret" :rules="[{ required: true, message: '请填写密钥' }]">
        <a-input v-model:value="bindForm.appsecret" placeholder="请输入密钥" :disabled="binding">
        </a-input>
      </a-form-item>
      <a-form-item label="备注" name="remark">
        <a-input v-model:value="bindForm.remark" placeholder="请输入备注" :disabled="binding">
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="carrierModal" centered title="设置平台认可物流" @ok="confirmCarrier" ok-text="确认" cancel-text="取消"
    :confirm-loading="carriersetting" :cancel-button-props="{ disabled: carriersetting }" :maskClosable="false"
    :closable="!carriersetting">
    <a-form ref="carrierRef" name="carrierForm" :model="carrierForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="亚马逊认可物流" name="amz_carrier_code" :rules="[{ required: true, message: '请选择物流方式' }]">
        <a-select v-model:value="carrierForm.amz_carrier_code" placeholder="选择承运人" :options="carriers"
          style="width: 100%" :disabled="carriersetting" show-search :filter-option="$filters.filterOption">
        </a-select>
      </a-form-item>
      <a-form-item label="亚马逊配送方式" name="amz_shipping_method">
        <a-input v-model:value="carrierForm.amz_shipping_method" placeholder="请输入配送方式" :disabled="carriersetting">
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="remarkModal" centered title="设置产品备注" @ok="confirmRemark" ok-text="确认" cancel-text="取消"
    :confirm-loading="remarksetting" :cancel-button-props="{ disabled: remarksetting }" :maskClosable="false"
    :closable="!remarksetting">
    <a-form ref="remarkRef" name="remarkForm" :model="remarkForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="备注" name="remark">
        <a-input v-model:value="remarkForm.remark" placeholder="请输入备注" :disabled="remarksetting">
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="authModal" centered title="授权子账号" @ok="confirmAuthSellers" ok-text="确认" cancel-text="取消"
    :confirm-loading="authing" :cancel-button-props="{ disabled: authing }" :maskClosable="false" :closable="!authing">
    <a-form name="authForm" :model="authForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="物流账号" name="appid">
        <a-input v-model:value="authForm.appid" disabled>
        </a-input>
      </a-form-item>
      <a-form-item label="选择子账号" name="sellerids">
        <a-checkbox-group v-model:value="authForm.sellerids" :options="authsellers" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, EditOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useStore } from "vuex";
import _ from "underscore";
const columns = [
  { title: '物流商', dataIndex: 'supplier', width: 150, },
  { title: '授权状态', dataIndex: 'authorize_status', width: 200, },
  { title: '授权子账号', dataIndex: 'bsellers', width: 100, },
  { title: '创建时间', dataIndex: 'create_time', width: 150 },
  { title: '创建人', dataIndex: 'create_by', width: 100 },
  { title: '备注', dataIndex: 'remark', width: 100 },
  { title: '操作', dataIndex: 'operation', width: 160, fixed: 'right' },
];
const pcolumns = [
  { title: '序号', dataIndex: 'index', width: 60, },
  { title: '物流产品', dataIndex: 'name', width: 200, },
  { title: '平台认可物流', dataIndex: 'carrier', width: 150, },
  { title: '启用状态', dataIndex: 'state', width: 100, },
  { title: '启用时间', dataIndex: 'time', width: 120, },
  { title: '备注', dataIndex: 'remark', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right', },
];
export default defineComponent({
  name: 'LogisticsSuppliers',
  components: {
    DownOutlined, EditOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const bindRef = ref();
    const carrierRef = ref();
    const remarkRef = ref();
    const store = useStore();
    const state = reactive({
      name: "",
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      binding: false,
      bindModal: false,
      bindForm: {},
      suppliers: [],
      channels: {},
      expandedRowKeys: [],
      channelLoading: false,
      carrierForm: {},
      carrierModal: false,
      carriersetting: false,
      carriers: [],
      remarkForm: {},
      remarkModal: false,
      remarksetting: false,
      authForm: {},
      authModal: false,
      authing: false,
      authsellers: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const pwidth = computed(() => proxy.$utils.twidth(pcolumns));
    const userid = computed(() => store.state.user.userid);
    const level = computed(() => store.state.user.level);
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      state.data = [];
      state.channels = {};
      state.expandedRowKeys = [];
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/logistics/supplierbinds", { page: state.page, limit: state.limit, name: state.name, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          if (state.expandedRowKeys.length > 0) {
            state.expandedRowKeys.forEach(n => {
              getChannels(n);
            });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const requestBind = (item) => {
      state.binding = true;
      proxy.$api.doAPI("/logistics/suppliers").then((res) => {
        state.binding = false;
        if (res.code === 0) {
          state.suppliers = _.map(res.data.list, n => ({ value: n._id, label: n.name }));
          state.bindForm = {
            _id: item?._id || "",
            logistics_supplier_id: item?.logistics_supplier_id || "",
            name: item?.name || "",
            appid: item?.appid || "",
            appsecret: item?.appsecret || "",
            remark: item?.remark || "",
          };
          state.bindModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.binding = false;
        console.error(err);
      });
    }
    const confirmBind = async () => {
      try {
        await bindRef.value.validateFields();
        state.binding = true;
        proxy.$api.doAPI("/logistics/confirmbind", { bindForm: state.bindForm, }).then((res) => {
          state.binding = false;
          if (res.code === 0) {
            message.success("授权成功");
            state.bindModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.binding = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const removeBind = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/logistics/removebind", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          message.success("删除成功");
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const showAuthSellers = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/logistics/showauthsellers").then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.authsellers = res.data.sellers;
          state.authForm = {
            _id: item._id,
            appid: item.appid,
            sellerids: item.sellerids,
          };
          state.authModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const confirmAuthSellers = () => {
      state.authing = true;
      proxy.$api.doAPI("/logistics/confirmauthsellers", { authForm: state.authForm }).then((res) => {
        state.authing = false;
        if (res.code === 0) {
          message.success("删除成功");
          state.authModal = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.authing = false;
        console.error(err);
      });
    }
    const changeSupplier = (e) => {
      console.log('changeSupplier', e);
      let supplier = _.findWhere(state.suppliers, { value: e });
      state.bindForm.name = supplier?.label || '';
    }
    const expand = (expanded, record) => {
      console.log(expanded, record);
      if (expanded) {
        getChannels(record._id);
      } else {
        state.channels[record._id] = [];
      }
    }
    const getChannels = (logistics_supplier_bind_id) => {
      state.channelLoading = true;
      proxy.$api.doAPI("/logistics/channels", { logistics_supplier_bind_id, }).then((res) => {
        state.channelLoading = false;
        if (res.code === 0) {
          state.channels[logistics_supplier_bind_id] = res.data.channels;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.channelLoading = false;
        console.error(err);
      });
    };
    const openChannel = (item, supplierbind) => {
      state.channelLoading = true;
      proxy.$api.doAPI("/logistics/openchannel", { logistics_supplier_bind_id: supplierbind._id, logistics_channel_id: item._id, }).then((res) => {
        state.channelLoading = false;
        if (res.code === 0) {
          getChannels(supplierbind._id);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.channelLoading = false;
        console.error(err);
      });
    }
    const closeChannel = (item, supplierbind) => {
      state.channelLoading = true;
      proxy.$api.doAPI("/logistics/closechannel", { logistics_supplier_bind_id: supplierbind._id, logistics_channel_bind_id: item.channelbind._id, }).then((res) => {
        state.channelLoading = false;
        if (res.code === 0) {
          getChannels(supplierbind._id);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.channelLoading = false;
        console.error(err);
      });
    }
    const showCarrier = (item, supplierbind) => {
      state.channelLoading = true;
      proxy.$api.doAPI("/logistics/carriers").then((res) => {
        state.channelLoading = false;
        if (res.code === 0) {
          state.carriers = _.map(res.data.list, n => ({ label: n.name, value: n.code }));
          state.carrierForm = {
            logistics_supplier_bind_id: supplierbind._id,
            logistics_channel_id: item._id,
            logistics_channel_bind_id: item.channelbind?._id || undefined,
            amz_carrier_code: item.channelbind?.amz_carrier_code || item.amz_carrier_code || undefined,
            amz_shipping_method: item.channelbind?.amz_shipping_method || "",
          };
          state.carrierModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.channelLoading = false;
        console.error(err);
      });
    }
    const confirmCarrier = async () => {
      try {
        await carrierRef.value.validateFields();
        state.carriersetting = true;
        proxy.$api.doAPI("/logistics/confirmcarrier", { carrierForm: state.carrierForm }).then((res) => {
          state.carriersetting = false;
          if (res.code === 0) {
            state.carrierModal = false;
            getChannels(state.carrierForm.logistics_supplier_bind_id);
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.carriersetting = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const showRemark = (item, supplierbind) => {
      state.remarkForm = {
        logistics_supplier_bind_id: supplierbind._id,
        logistics_channel_bind_id: item.channelbind._id,
        remark: item.channelbind?.remark || "",
      };
      state.remarkModal = true;
    }
    const confirmRemark = async () => {
      try {
        await remarkRef.value.validateFields();
        state.remarksetting = true;
        proxy.$api.doAPI("/logistics/confirmremark", { remarkForm: state.remarkForm }).then((res) => {
          state.remarksetting = false;
          if (res.code === 0) {
            state.remarkModal = false;
            getChannels(state.remarkForm.logistics_supplier_bind_id);
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.remarksetting = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    return {
      ...toRefs(state),
      bindRef,
      carrierRef,
      remarkRef,
      columns,
      pcolumns,
      width,
      pwidth,
      userid,
      level,
      getData,
      requestBind,
      confirmBind,
      removeBind,
      showAuthSellers,
      confirmAuthSellers,
      changeSupplier,
      expand,
      openChannel,
      closeChannel,
      showCarrier,
      confirmCarrier,
      showRemark,
      confirmRemark,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
